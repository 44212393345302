import { useState } from "react";
import "./App.css";

function App() {
  const [inputValue, setInputValue] = useState("");
  const [list, setList] = useState([]);

  const handleClick = () => {
    setList([...list, inputValue]);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <h1>Lista para Testes</h1>
          <div>
            <input
              data-testid="input-test"
              type="text"
              placeholder="informe um valor"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            {inputValue.length == 0 ? (
              <button onClick={handleClick} disabled data-testid="buttonTest">
                Enviar
              </button>
            ) : (
              <button onClick={handleClick} data-testid="buttonTest">
                Enviar
              </button>
            )}
          </div>
          <div>
            <ul data-testid="list">
              {list.map((itemList, index) => {
                return <li key={index}>{itemList}</li>;
              })}
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
